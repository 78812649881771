import Vue from 'vue'
import Vuex from 'vuex'

import { identity } from '@/store/modules/identity.module';
import { importData } from '@/store/modules/importData.module';
import { bestandItem } from '@/store/modules/bestandItem.module';
import { bestandImport } from '@/store/modules/bestandImport.module';
import { excelReport } from '@/store/modules/excelReport.module';

Vue.config.devtools = true
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        identity,
        importData,
        bestandItem,
        bestandImport,
        excelReport
    }
})