<template>
    <div class="post">

        <b-row class="mb-3">
            <b-col col md="6">

                <b-card footer-tag="footer" header-tag="header">

                    <template #header>
                        <h6 class="mb-0"><strong>Filtry</strong></h6>
                    </template>

                    <b-card-text>
                        <b-row>
                            <b-col>
                                <label for="input-bestellungen">Bestellungen > [zadaná hodnota]:</label>
                                <b-input-group>
                                    <b-form-input id="input-bestellungen"
                                                  v-model="bestellungen"
                                                  type="number"
                                                  size="sm"
                                                  aria-describedby="input-bestellungen-help input-bestellungen-feedback"
                                                  placeholder="Bestellungen"
                                                  trim></b-form-input>
                                    <b-input-group-append>
                                        <b-button size="sm" variant="info" @click="bestellungen = 100000"> 100.000,00</b-button>
                                    </b-input-group-append>
                                    <b-input-group-append>
                                        <b-button size="sm" variant="danger" @click="bestellungen = null">X</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                            <b-col>
                                <label for="input-uhartikel"> {{texts.uhArtikelFilterText}}:</label>
                                <b-input-group>
                                    <b-form-input id="input-uhartikel"
                                                  v-model="uhartikel"
                                                  type="number"
                                                  size="sm"
                                                  aria-describedby="input-uhartikel-help input-uhartikel-feedback"
                                                  placeholder="UH Artikel"
                                                  trim></b-form-input>
                                    <b-input-group-append>
                                        <b-button size="sm" variant="danger" @click="uhartikel = null">X</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                            <b-col>
                                <label>Disponent:</label>
                                <b-form-select v-model="selectedDisponent" :options="disponentsData" size="sm"></b-form-select>
                            </b-col>
                        </b-row>

                        <b-row class="mt-2">
                            <b-col>
                                <label>Standardpreis Min:</label>
                                <b-form-input id="input-standardpreisMin"
                                              v-model="standardpreisMin"
                                              type="number"
                                              size="sm"
                                              step="0.5"
                                              aria-describedby="input-standardpreisMin-help input-standardpreisMin-feedback"
                                              placeholder="Standardpreis Min"
                                              trim></b-form-input>
                            </b-col>
                            <b-col>
                                <label>Standardpreis Max:</label>
                                <b-form-input id="input-standardpreisMax"
                                              v-model="standardpreisMax"
                                              type="number"
                                              size="sm"
                                              step="0.5"
                                              aria-describedby="input-standardpreisMax-help input-standardpreisMax-feedback"
                                              placeholder="Standardpreis Max"
                                              trim></b-form-input>
                            </b-col>
                            <b-col>
                                <b-form-checkbox id="checkbox-1"
                                                 v-model="verb12SicherBestand"
                                                 name="checkbox-1"
                                                 :value="true"
                                                 size="sm"
                                                 :unchecked-value="false">
                                    Verbr.=0, SBe >0
                                </b-form-checkbox>
                            </b-col>
                        </b-row>

                        <b-form-text id="input-bestellungen-help">Pro zrušení filtru ponechte prázdné.</b-form-text>

                    </b-card-text>

                    <template #footer>
                        <div class="text-right">
                            <b-button variant="danger" @click="removeAllFilters()" class="mr-2">Zrušit všechny filtry</b-button>
                            <b-button variant="success" @click="reloadDataForDataTable(false, lastImportId)">Filtrovat</b-button>
                        </div>
                    </template>
                </b-card>


            </b-col>
            <b-col col md="6">
                <b-row>
                    <b-col>
                        <label for="import-excel-input">Import excelu:</label>
                        <b-input-group>
                            <b-form-file id="import-excel-inputs" size="sm" type="file" class="form-control" @change="onChangeFile"></b-form-file>
                            <b-input-group-append>
                                <b-button size="sm" variant="success" @click="startImportData" :disabled="!importData.excel">Importovat data z excelu</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col>
                        <b-button variant="success" @click="exportDataToExcel">Exportovat do excelu</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-pagination v-model="tableData.currentPage"
                              :total-rows="tableData.totalCount"
                              :per-page="tableData.pageSize"
                              aria-controls="my-table"
                              @change="handlePageChange"
                              limit="10"
                              first-number
                              last-number></b-pagination>

                <b-table striped
                         bordered
                         hover
                         responsive
                         small
                         head-variant="dark"
                         :busy="tableDataLoading"
                         :items="tableData.dataBestandItems"
                         :fields="table.fields"
                         tbody-class="align-middle"
                         :sort-by.sync="table.sortBy"
                         :sort-desc.sync="table.sortDesc"
                         class="mt-2">
                </b-table>

                <b-pagination v-model="tableData.currentPage"
                              :total-rows="tableData.totalCount"
                              :per-page="tableData.pageSize"
                              aria-controls="my-table"
                              @change="handlePageChange"
                              limit="10"
                              first-number
                              last-number></b-pagination>

            </b-col>
        </b-row>

    </div>
</template>

<script lang="js">

    import { defineComponent } from 'vue';

    export default defineComponent({
        name: "TableWithCompleteData",
        components: {
        },
        props: {
            parentFunc: null
        },
        data() {
            return {
                texts: {
                    uhArtikelFilterText: "UH Artikel < [zadaná hodnota]"
                },
                importData: {
                    excel: null,
                },
                currentPage: 1,
                lastImportId: -1,
                bestellungen: null,
                uhartikel: null,
                standardpreisMin: null,
                standardpreisMax: null,
                verb12SicherBestand: false,
                selectedDisponent: null,
                table: {
                    sortBy: 'age',
                    sortDesc: false,
                    fields: [
                        { key: 'artikelnummer', label: "Artikelnummer", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'artikelbezeichn', label: "Artikelbezeichn", sortable: false, allowFilterCheckbox: false, class: "text-left" },
                        { key: 'beschaffungsart', label: "Beschaffungsart", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'inventurgr', label: "Inventurgr", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'kontengruppe', label: "Kontengruppe", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'lagerort', label: "Lagerort", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'preisEHStdPr', label: "Preis EH Std.Pr.", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'preiseinheit', label: "Preiseinheit", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'lagermengeneinheit', label: "Lagermengeneinheit", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'disponent', label: "Disponent", sortable: false, allowFilterCheckbox: false, class: "text-left" },
                        { key: 'phyLagerbestand', label: "Phy.Lagerbestand", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sicherhBestand', label: "Sicherh.Bestand", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'standardpreis', label: "Standardpreis", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sPxBestand', label: "SP x Bestand", sortable: false, allowFilterCheckbox: false, class: "text-right", variant: "warning" },
                        { key: 'bestellbstEinkauf', label: "Bestellbst. Einkauf", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sPxBestelmenge', label: "SP x Bestelmenge", sortable: false, allowFilterCheckbox: false, class: "text-right", variant: "warning" },
                        { key: 'bestellbstFertigung', label: "Bestellbst.Fertigung", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'gesamtbedarf', label: "Gesamtbedarf", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'verbrauch1', label: "1. Verbrauch", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'verbrauch2', label: "2. Verbrauch", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'verbrauch3', label: "3. Verbrauch", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'verbLtz12Mon', label: "Verb.ltz.12 Mon.", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'verbLtz24Mon', label: "Verb.ltz.24 Mon.", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sPxBestand', label: "SPxBestand", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sPxBestelmenge', label: "SP x Bestelmenge", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'uhArtikel', label: "UH Artikel", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sPxVerbrauch12Monate', label: "SP x Verbrauch12. Monate", sortable: false, allowFilterCheckbox: false, class: "text-right", variant: "warning" },
                        //{ key: 'actions', label: "Akce", sortable: false, allowFilterCheckbox: false, class: "text-right" },

                    ],
                    staticTexts: {
                        loading: "Nahrávám...",
                        emptyTable: "Zatím zde nejsou žádná data"
                    }
                },
            };
        },
        created() {
        },
        computed: {
            tableData() {
                return this.$store.state.bestandItem.bestandItems.data;
            },
            tableDataLoading() {
                return this.$store.state.bestandItem.bestandItems.status.loading;
            },
            disponentsData() {
                var disponents = [{ value: null, text: "Vyberte disponenta" }]
                this.$store.state.bestandItem.disponentsForImport.data.forEach(function (item) {
                    disponents.push({ value: item, text: item });
                });
                return disponents;
            }
        },
        methods: {
            checkIfPageChangeOnly(selectedImportId) {
                if (selectedImportId > 0 && selectedImportId != this.lastImportId) {
                    this.currentPage = 1;
                }
            },
            checkIfImportIdChange(selectedImportId) {
                if (selectedImportId > 0) {
                    this.lastImportId = selectedImportId;
                }
            },
            reloadDataForDataTable(onlyPageChange, selectedImportId) {

                this.checkIfPageChangeOnly(selectedImportId);
                this.checkIfImportIdChange(selectedImportId);

                this.verb12SicherBestand = Boolean(this.verb12SicherBestand);

                let thisHelper = this;

                this.$store.dispatch("bestandItem/getBestandItems", {
                    pageNumber: this.currentPage,
                    onlyPageChange: onlyPageChange,
                    importId: this.lastImportId,
                    bestellungen: this.bestellungen ? this.bestellungen : null,
                    uhartikel: this.uhartikel ? this.uhartikel : null,
                    standardpreisMin: this.standardpreisMin ? this.standardpreisMin : null,
                    standardpreisMax: this.standardpreisMax ? this.standardpreisMax : null,
                    verb12SicherBestand: this.verb12SicherBestand,
                    disponent: this.selectedDisponent
                }).then(function () {
                    thisHelper.$store.dispatch("bestandItem/getAllDisponentsForImport", thisHelper.lastImportId);
                });
            },
            onChangeFile(event) {
                this.importData.excel = event.target.files[0];
            },
            startImportData() {
                let thisHelper = this;
                this.$store.dispatch("importData/startImportData", this.importData).then(function () {
                    thisHelper.parentFunc();
                });
            },
            handlePageChange(value) {
                this.currentPage = value;
                this.reloadDataForDataTable(true);
            },
            removeAllFilters() {
                this.bestellungen = null;
                this.verb12SicherBestand = false;
                this.uhartikel = null;
                this.standardpreisMin = null;
                this.standardpreisMax = null;
                this.selectedDisponent = null;

                this.reloadDataForDataTable(false, this.lastImportId);
            },
            exportDataToExcel() {

                console.log(this.lastImportId);

                this.$store.dispatch('excelReport/getExcel', {
                    bestandImportId: this.lastImportId,
                    bestellungen: this.bestellungen ? this.bestellungen : null,
                    uhartikel: this.uhartikel ? this.uhartikel : null,
                    standardpreisMin: this.standardpreisMin ? this.standardpreisMin : null,
                    standardpreisMax: this.standardpreisMax ? this.standardpreisMax : null,
                    verb12SicherBestand: this.verb12SicherBestand,
                    disponent: this.selectedDisponent
                });
            }
        },
    });
</script>

<style scoped>
    label {
        font-weight: bold;
    }
    .card-body {
        outline-style: solid !important;
    }
</style>