
import axios from 'axios'

const baseUrl = '/api/BestandImport/';

export const bestandImportService = {
    getImports,
};

async function getImports() {

    let result = await axios.get(`${baseUrl}GetImports`)
    return result.data;

}
