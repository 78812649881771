
//import { getCurrentInstance } from 'vue'
import { importDataService } from '@/services'

const state = {
    startImportData: {
        status: {
            loading: false
        },
        data: {}
    }
}


const mutations = {

    startImportRequest(state) {
        state.startImportData.status.loading = true;
    },
    startImportSuccess(state, data) {
        state.startImportData.status.loading = false;
        state.startImportData.data = data;
    },
    startImportFailure(state) {
        state.startImportData.status.loading = false;
        state.startImportData.data = [];
    },

}

const actions = {

    startImportData: async function ({ commit }, payload) {

        commit('startImportRequest');
        await importDataService.startImport(payload)
            .then(
                data => {
                    commit('startImportSuccess', data);
                },
                error => {
                    commit('startImportFailure', error);
                    console.error(error)
                    throw error.data;
                }
            );
    },
}

export const importData = {
    namespaced: true,
    state,
    actions,
    mutations
};