
import axios from 'axios'

const baseUrl = '/api/BestandItem/';

export const bestandItemService = {
    getBestandItemsForImport,
    getDataForKT,
    getDataForKTSpecific,
    getDataForKTRowDetail,
    getAllDisponentsForImport,
    getDataForKTSpecificRowDetail
};

async function getBestandItemsForImport(formData) {

    let result = await axios.post(`${baseUrl}GetBestandItemsForImport`, formData)
    return result.data;

}

async function getDataForKT(formData) {
    let result = await axios.post(`${baseUrl}GetDataForKT`, formData)
    return result.data;
}

async function getDataForKTSpecific(formData) {
    let result = await axios.post(`${baseUrl}GetDataForKTSpecific`, formData)
    return result.data;
}

async function getDataForKTRowDetail(formData) {
    let result = await axios.post(`${baseUrl}GetDataForKTRowDetail`, formData)
    return result.data;
} 

async function getDataForKTSpecificRowDetail(formData) {
    let result = await axios.post(`${baseUrl}GetDataForKTSpecificRowDetail`, formData)
    return result.data;
}

async function getAllDisponentsForImport(importId) {

    let result = await axios.get(`${baseUrl}GetAllDisponentsForImport`, {
        params: {
            importId: importId
        }
    });
    return result.data;

}