import { bestandImportService } from '@/services'

const state = {
    bestandImports: {
        status: {
            loading: true
        },
        data: []
    },
}

const mutations = {

    getBestandImportsRequest() {
        state.bestandImports.status.loading = true;
    },
    getBestandImportsSuccess(state, items) {
        state.bestandImports.status.loading = false;
        state.bestandImports.data = items;
    },
    getBestandImportsFailure(state) {
        state.bestandImports.status.loading = false;
        state.bestandImports.data = [];
    },

}

const actions = {

    getImports: async function ({ commit }) {
        commit('getBestandImportsRequest');
        await bestandImportService.getImports()
            .then(
                data => {
                    commit('getBestandImportsSuccess', data);
                },
                error => {
                    commit('getBestandImportsFailure', error);
                    throw error.data;
                }
            );
    },
 
}

export const bestandImport = {
    namespaced: true,
    state,
    actions,
    mutations
};