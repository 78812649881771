<template>
    <div class="post">

        <loading :active.sync="commonLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 class="text-center">
            <template v-slot:before>
                <h1 class="text-center">Zpracovávám, prosím o chvilku strpení...</h1>
            </template>
            <template v-slot:after>
                <h1 class="text-center">Tato operace může trvat i několik minut v závislosti na množství dat...</h1>
            </template>
        </loading>

        <b-row>
            <b-col>
                <b-form-select v-model="selectedImportId" :options="allImportsForSelect" @change="handleImportChange"></b-form-select>
            </b-col>
        </b-row>

        <b-row class="mt-2">
            <b-col>
                <b-tabs content-class="mt-3"
                        active-nav-item-class="font-weight-bold text-uppercase text-danger"
                        fill>
                    <b-tab title="Data" active>
                        <table-with-complete-data :selected-import-id="selectedImportId" ref="dataTable" :parent-func="getAllIports"></table-with-complete-data>
                    </b-tab>
                    <b-tab title="KT">
                        <template #title>
                            <span>KT</span>
                        </template>
                        <b-row>
                            <b-col>
                                <b-button class="mb-2" variant="success" @click="getAllKTExcels">Exportovat všechny KT do excelu</b-button>
                                <b-row>
                                    <b-col>
                                        <kt-table :beschaffungsarts.sync="kt2.beschaffungsarts" ref="kt2" :parent-func="setKTRowDetailData"></kt-table>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <kt-table :beschaffungsarts.sync="kt1.beschaffungsarts" ref="kt1" :parent-func="setKTRowDetailData"></kt-table>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <kt-table :beschaffungsarts.sync="kt3.beschaffungsarts" ref="kt3" :parent-func="setKTRowDetailData"></kt-table>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <kt-table-specific :beschaffungsarts.sync="kt2.beschaffungsarts" ref="kt4S" :parent-func="setKTRowDetailData"></kt-table-specific>
                                    </b-col>
                                </b-row>
                                <kt-table-row-detail ref="rowdetailtable" :input-data.sync="ktRowDetailInputData" v-show="ktRowDetailInputData.selectedDisponent != ''"></kt-table-row-detail>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>

    </div>
</template>

<script lang="js">

    import { defineComponent } from 'vue';
    import loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    import tableWithCompleteData from '@/components/TableWithCompleteData'
    import ktTable from '@/components/KTTable'
    import ktTableSpecific from '@/components/KTTableSpecific'
    import ktTableRowDetail from '@/components/KTRowDetailTable'

    export default defineComponent({
        name: "HomePage",
        components: {
            loading,
            tableWithCompleteData,
            ktTable,
            ktTableSpecific,
            ktTableRowDetail
        },
        data() {
            return {
                selectedImportId: -1,
                ktRowDetailInputData: {
                    selectedDisponent: "",
                    beschaffungsarts: []
                },
                kt1: { beschaffungsarts: ["K", "X"] },
                kt2: { beschaffungsarts: ["X", "K", "Y"] },
                kt3: { beschaffungsarts: ["Y"] }
            };
        },
        created() {
            this.getAllIports();
        },
        computed: {
            commonLoading() {
                if (this.$store.state.bestandItem.bestandItems.status.loading ||
                    this.$store.state.bestandImport.bestandImports.status.loading ||
                    this.$store.state.importData.startImportData.status.loading ||
                    this.$store.state.excelReport.getExcel.status.loading ||
                    this.$store.state.bestandItem.bestandKTSpecific.status.loadings) {
                    return true;
                }

                return false;
            },
            tableDataLoading() {
                return this.$store.state.bestandItem.bestandItems.status.loading;
            },
            allImportsLoading() {
                return this.$store.state.bestandImport.bestandImports.status.loading;
            },
            allImports() {
                return this.$store.state.bestandImport.bestandImports.data;
            },
            allImportsForSelect() {
                return this.$store.state.bestandImport.bestandImports.data.map(function (item) {
                    return { value: item.id, text: item.created }
                })
            },
            KTRowDetailData() {
                return this.$store.state.bestandItem.bestandKTRowDetailTable.data;
            }
        },
        methods: {
            getAllKTExcels() {
                return this.$store.dispatch("excelReport/getAllKTTablesExcel", this.selectedImportId);
            },
            getAllIports() {
                console.log("Volam a aktualizuji importy + nastavuji ten posledni");
                let thisHelper = this;
                this.$store.dispatch("bestandImport/getImports").then(function () {
                    thisHelper.selectedImportId = thisHelper.allImports[0].id;
                    thisHelper.$refs.dataTable.reloadDataForDataTable(false, thisHelper.allImports[0].id);
                    thisHelper.$refs.kt1.reloadData(thisHelper.allImports[0].id, "kt1").then(function () {
                        thisHelper.$refs.kt2.reloadData(thisHelper.allImports[0].id, "kt2").then(function () {
                            thisHelper.$refs.kt3.reloadData(thisHelper.allImports[0].id, "kt3").then(function () {
                                thisHelper.$refs.kt4S.reloadData(thisHelper.allImports[0].id);
                            });
                        });
                    });


                });
            },
            handleImportChange() {
                this.currentPage = 1;
                let thisHelper = this;
                this.ktRowDetailInputData.selectedDisponent = "";
                this.$refs.dataTable.reloadDataForDataTable(false, thisHelper.selectedImportId);
                thisHelper.$refs.kt1.reloadData(thisHelper.selectedImportId, "kt1").then(function () {
                    thisHelper.$refs.kt2.reloadData(thisHelper.selectedImportId, "kt2").then(function () {
                        thisHelper.$refs.kt3.reloadData(thisHelper.selectedImportId, "kt3").then(function () {
                            thisHelper.$refs.kt4S.reloadData(thisHelper.selectedImportId);
                        });
                    });
                });
            },
            setKTRowDetailData(disponent, beschaffungsarts, methodName) {
                let thisHelper = this;
                thisHelper.ktRowDetailInputData.selectedDisponent = disponent;
                thisHelper.ktRowDetailInputData.beschaffungsarts = beschaffungsarts;
                thisHelper.$refs.rowdetailtable.reloadDataForDataTable(false, thisHelper.selectedImportId, methodName);
            }
        },
    });
</script>

<style>
    /* Barvy pozadí pro jednotlivé záložky */
    .nav-tabs .nav-item:nth-child(1) .nav-link {
        background-color: lightblue; /* Barva pozadí pro první záložku */
    }

    .nav-tabs .nav-item:nth-child(2) .nav-link {
        background-color: lightgreen; /* Barva pozadí pro druhou záložku */
    }
</style>