import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import HomePage from '@/pages/HomePage'

const ifAuthenticated = (to, from, next) => {

    const allowedRoles = to.meta.allowedRoles;

    if (localStorage.getItem('user')) {
        try {
            const loggedIn = JSON.parse(localStorage.getItem('user'));
            console.log(loggedIn);
            var pageAllowedRoles = allowedRoles.indexOf(loggedIn.role);

            if (pageAllowedRoles != -1) {
                return next();
            }
        } catch (e) {
            next('/prihlaseni')
        }
    }

    next('/prihlaseni')
}

const routes = [
    {
        path: '/',
        name: "home",
        component: HomePage,
        meta: {
            title: "Bestandsbericht",
            //allowedRoles: ["Admin", "DepartmentHead", "ShiftMaster"]
        },
        //beforeEnter: ifAuthenticated,
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }
]

export default new Router({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    mode: 'history',
    base: process.env.BASE_URL,
    routes, // short for `routes: routes`
})
