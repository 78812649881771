
import Vue from 'vue'
import { excelReportService } from '@/services'

const state = {
    getExcel: {
        status: {
            loading: false
        },
        data: []
    },

}

const mutations = {

    getExcelDataRequest() {
        state.getExcel.status.loading = true;
    },
    getExcelDataSuccess(state, data) {
        state.getExcel.status.loading = false;
        state.getExcel.data = data;
    },
    getExcelDataFailure(state) {
        state.getExcel.status.loading = false;
        state.getExcel.data = [];
    },

}

const actions = {

    getAllKTTablesExcel: async function ({ commit }, importId) {
        commit('getExcelDataRequest');
        await excelReportService.getAllKTTablesExcel({ importId: importId, Beschaffungsarts: [] })
            .then(
                data => {
                    commit('getExcelDataSuccess', data);
                },
                error => {
                    commit('getExcelDataFailure', error);
                    throw error.data;
                }
            );
    },
    getKTTableExcel: async function ({ commit }, model) {
        commit('getExcelDataRequest');
        await excelReportService.getKTTableExcel(model)
            .then(
                data => {
                    commit('getExcelDataSuccess', data);
                },
                error => {
                    commit('getExcelDataFailure', error);
                    throw error.data;
                }
            );
    },

    getExcel: async function ({ commit }, model) {
        commit('getExcelDataRequest');
        await excelReportService.getExcel(model)
            .then(
                data => {
                    commit('getExcelDataSuccess', data);
                },
                error => {
                    commit('getExcelDataFailure', error);
                    throw error.data;
                }
            );
    },

    getExcelForKTRowDetail: async function ({ commit }, model) {
        commit('getExcelDataRequest');
        await excelReportService.getExcelForKTRowDetail(model)
            .then(
                data => {
                    commit('getExcelDataSuccess', data);
                },
                error => {
                    commit('getExcelDataFailure', error);
                    throw error.data;
                }
            );
    },

    getKTSpecificTableExcel: async function ({ commit }, model) {
        commit('getExcelDataRequest');
        await excelReportService.getKTSpecificTableExcel(model)
            .then(
                data => {
                    commit('getExcelDataSuccess', data);
                },
                error => {
                    commit('getExcelDataFailure', error);
                    throw error.data;
                }
            );
    },

}

export const excelReport = {
    namespaced: true,
    state,
    actions,
    mutations
};