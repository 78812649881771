<template>
    <div class="post">

        <b-row>
            <b-col>

                <h5>Detail: <small>disponent: {{inputData.selectedDisponent}}, beschaffungsarts: {{inputData.beschaffungsarts.toString()}}</small></h5>

                <b-pagination v-model="tableData.currentPage"
                              :total-rows="tableData.totalCount"
                              :per-page="tableData.pageSize"
                              aria-controls="my-table"
                              @change="handlePageChange"
                              limit="10"
                              first-number
                              last-number></b-pagination>

                <b-table striped
                         bordered
                         hover
                         responsive
                         small
                         head-variant="dark"
                         :busy="tableDataLoading"
                         :items.sync="tableData.dataBestandItems"
                         :fields="table.fields"
                         tbody-class="align-middle"
                         :sort-by.sync="table.sortBy"
                         :sort-desc.sync="table.sortDesc"
                         class="mt-2">

                </b-table>

                <b-pagination v-model="tableData.currentPage"
                              :total-rows="tableData.totalCount"
                              :per-page="tableData.pageSize"
                              aria-controls="my-table"
                              @change="handlePageChange"
                              limit="10"
                              first-number
                              last-number></b-pagination>

            </b-col>
        </b-row>

    </div>
</template>

<script lang="js">

    import { defineComponent } from 'vue';

    export default defineComponent({
        name: "ktRowDetailTable",
        components: {
        },
        props: {
            inputData: {}
        },
        data() {
            return {
                currentPage: 1,
                lastImportId: -1,
                table: {
                    sortBy: 'age',
                    sortDesc: false,
                    fields: [
                        { key: 'artikelnummer', label: "Artikelnummer", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'artikelbezeichn', label: "Artikelbezeichn", sortable: false, allowFilterCheckbox: false, class: "text-left" },
                        { key: 'beschaffungsart', label: "Beschaffungsart", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'inventurgr', label: "Inventurgr", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'kontengruppe', label: "Kontengruppe", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'lagerort', label: "Lagerort", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'preisEHStdPr', label: "Preis EH Std.Pr.", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'preiseinheit', label: "Preiseinheit", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'lagermengeneinheit', label: "Lagermengeneinheit", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'disponent', label: "Disponent", sortable: false, allowFilterCheckbox: false, class: "text-left" },
                        { key: 'phyLagerbestand', label: "Phy.Lagerbestand", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sicherhBestand', label: "Sicherh.Bestand", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'standardpreis', label: "Standardpreis", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sPxBestand', label: "SP x Bestand", sortable: false, allowFilterCheckbox: false, class: "text-right", variant: "warning" },
                        { key: 'bestellbstEinkauf', label: "Bestellbst. Einkauf", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sPxBestelmenge', label: "SP x Bestelmenge", sortable: false, allowFilterCheckbox: false, class: "text-right", variant: "warning" },
                        { key: 'bestellbstFertigung', label: "Bestellbst.Fertigung", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'gesamtbedarf', label: "Gesamtbedarf", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'verbrauch1', label: "1. Verbrauch", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'verbrauch2', label: "2. Verbrauch", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'verbrauch3', label: "3. Verbrauch", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'verbLtz12Mon', label: "Verb.ltz.12 Mon.", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'verbLtz24Mon', label: "Verb.ltz.24 Mon.", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sPxBestand', label: "SPxBestand", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sPxBestelmenge', label: "SP x Bestelmenge", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'uhArtikel', label: "UH Artikel", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sPxVerbrauch12Monate', label: "SP x Verbrauch12. Monate", sortable: false, allowFilterCheckbox: false, class: "text-right", variant: "warning" },
                    ],
                    staticTexts: {
                        loading: "Nahrávám...",
                        emptyTable: "Zatím zde nejsou žádná data"
                    }
                },
            };
        },
        created() {
        },
        computed: {
            tableData() {
                return this.$store.state.bestandItem.bestandKTRowDetailTable.data;
            },
            tableDataLoading() {
                return this.$store.state.bestandItem.bestandKTRowDetailTable.status.loading;
            },
        },
        methods: {
            checkIfPageChangeOnly(selectedImportId) {
                if (selectedImportId > 0 && selectedImportId != this.lastImportId) {
                    this.currentPage = 1;
                }
            },
            checkIfImportIdChange(selectedImportId) {
                if (selectedImportId > 0) {
                    this.lastImportId = selectedImportId;
                }
            },
            reloadDataForDataTable(onlyPageChange, selectedImportId, methodName) {

                this.checkIfPageChangeOnly(selectedImportId);
                this.checkIfImportIdChange(selectedImportId);

                this.$store.dispatch("bestandItem/" + methodName, {
                    pageNumber: this.currentPage,
                    onlyPageChange: onlyPageChange,
                    importId: this.lastImportId,
                    disponent: this.inputData.selectedDisponent,
                    beschaffungsarts: this.inputData.beschaffungsarts

                });
            },
            handlePageChange(value) {
                let thisHelper = this;
                thisHelper.currentPage = value;
                thisHelper.reloadDataForDataTable(true, thisHelper.lastImportId);
            },
        },
    });
</script>

<style>
</style>