
import axios from 'axios'

const baseUrl = '/api/ExcelReport/';

export const excelReportService = {
    getAllKTTablesExcel,
    getKTTableExcel,
    getExcel,
    getExcelForKTRowDetail,
    getKTSpecificTableExcel
};

async function getExcelForKTRowDetail(model) {
    let result = await axios.post(`${baseUrl}GetExcelForKTRowDetail`, model, {
        responseType: 'blob'
    }).then((response) => {
        processExcel(response);
    });

    return result.data;
}

async function getAllKTTablesExcel(model) {
    let result = await axios.post(`${baseUrl}GetAllKTTablesExcel`, model, {
        responseType: 'blob'
    }).then((response) => {
        processExcel(response);
    });

    return result.data;
}

async function getKTTableExcel(model) {
    let result = await axios.post(`${baseUrl}GetKTTableExcel`, model, {
        responseType: 'blob'
    }).then((response) => {
        processExcel(response);
    });

    return result.data;
} 

async function getKTSpecificTableExcel(model) {
    let result = await axios.post(`${baseUrl}GetKTSpecificTableExcel`, model, {
        responseType: 'blob'
    }).then((response) => {
        processExcel(response);
    });

    return result.data;
} 

async function getExcel(model) {
    let result = await axios.post(`${baseUrl}GetExcel`, model, {
        responseType: 'blob'
    }).then((response) => {
        processExcel(response);
    });

    return result.data;
}

function processExcel(response) {
    var headerval = response.headers['content-disposition'];
    var fileName = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');

    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    var fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);

    fileLink.click();
}