import { bestandItemService } from '@/services'

const state = {
    bestandItems: {
        status: {
            loading: true
        },
        data: []
    },
    bestandKTTables: {
        status: {
            loading: true
        },
        data: {}
    },
    bestandKTRowDetailTable: {
        status: {
            loading: true
        },
        data: {}
    },
    disponentsForImport: {
        status: {
            loading: true
        },
        data: []
    },
    bestandKTSpecific: {
        status: {
            loading: true
        },
        data: []
    },
}

const mutations = {

    getBestandItemsRequest() {
        state.bestandItems.status.loading = true;
    },
    getBestandItemsSuccess(state, items) {
        state.bestandItems.status.loading = false;
        state.bestandItems.data = items;
    },
    getBestandItemsFailure(state) {
        state.bestandItems.status.loading = false;
        state.bestandItems.data = [];
    },

    getDataForKTRequest(state, ktTableId) {
        state.bestandKTTables.status.loading = true;
        state.bestandKTTables.data[ktTableId] = [];
    },
    getDataForKTSuccess(state, response) {
        state.bestandKTTables.status.loading = false;
        state.bestandKTTables.data[response.ktTableId] = response.data;
    },
    getDataForKTFailure(state, response) {
        state.bestandKTTables.status.loading = false;
        state.bestandKTTables.data[response.ktTableId] = [];
    },

    getDataForKTRowDetailRequest(state) {
        state.bestandKTRowDetailTable.status.loading = true;
        state.bestandKTRowDetailTable.data = {};
    },
    getDataForKTRowDetailSuccess(state, response) {
        state.bestandKTRowDetailTable.status.loading = false;
        state.bestandKTRowDetailTable.data = response.data;
    },
    getDataForKTRowDetailFailure(state) {
        state.bestandKTRowDetailTable.status.loading = false;
        state.bestandKTRowDetailTable.data = {};
    },

    getAllDisponentsForImportRequest() {
        state.disponentsForImport.status.loading = true;
    },
    getAllDisponentsForImportSuccess(state, items) {
        state.disponentsForImport.status.loading = false;
        state.disponentsForImport.data = items;
    },
    getAllDisponentsForImportFailure(state) {
        state.disponentsForImport.status.loading = false;
        state.disponentsForImport.data = [];
    },

    getDataForKTSpecificRequest() {
        state.bestandKTSpecific.status.loading = true;
    },
    getDataForKTSpecificSuccess(state, items) {
        state.bestandKTSpecific.status.loading = false;
        state.bestandKTSpecific.data = items;
    },
    getDataForKTSpecificFailure(state) {
        state.bestandKTSpecific.status.loading = false;
        state.bestandKTSpecific.data = [];
    },

}

const actions = {

    getBestandItems: async function ({ commit }, payload) {
        commit('getBestandItemsRequest');
        await bestandItemService.getBestandItemsForImport(payload)
            .then(
                data => {
                    commit('getBestandItemsSuccess', data);
                },
                error => {
                    commit('getBestandItemsFailure', error);
                    throw error.data;
                }
            );
    },

    getDataForKT: async function ({ commit }, payload) {

        commit('getDataForKTRequest', payload.ktTableId);
        return bestandItemService.getDataForKT(payload)
            .then(
                data => {
                    commit('getDataForKTSuccess', { data: data, ktTableId: payload.ktTableId });
                },
                error => {
                    commit('getDataForKTFailure', { error: error, ktTableId: payload.ktTableId });
                    throw error.data;
                }
            );

    },

    getDataForKTRowDetail: async function ({ commit }, payload) {

        commit('getDataForKTRowDetailRequest', payload.ktTableId);
        return bestandItemService.getDataForKTRowDetail(payload)
            .then(
                data => {
                    commit('getDataForKTRowDetailSuccess', { data: data, ktTableId: payload.ktTableId });
                },
                error => {
                    commit('getDataForKTRowDetailFailure', { error: error, ktTableId: payload.ktTableId });
                    throw error.data;
                }
            );

    },

    getDataForKTSpecificRowDetail: async function ({ commit }, payload) {

        commit('getDataForKTRowDetailRequest', payload.ktTableId);
        return bestandItemService.getDataForKTSpecificRowDetail(payload)
            .then(
                data => {
                    commit('getDataForKTRowDetailSuccess', { data: data, ktTableId: payload.ktTableId });
                },
                error => {
                    commit('getDataForKTRowDetailFailure', { error: error, ktTableId: payload.ktTableId });
                    throw error.data;
                }
            );

    },

    getAllDisponentsForImport: async function ({ commit }, payload) {
        commit('getAllDisponentsForImportRequest');
        await bestandItemService.getAllDisponentsForImport(payload)
            .then(
                data => {
                    commit('getAllDisponentsForImportSuccess', data);
                },
                error => {
                    commit('getAllDisponentsForImportFailure', error);
                    throw error.data;
                }
            );
    },

    getDataForKTSpecific: async function ({ commit }, payload) {
        commit('getDataForKTSpecificRequest');
        await bestandItemService.getDataForKTSpecific(payload)
            .then(
                data => {
                    commit('getDataForKTSpecificSuccess', data);
                },
                error => {
                    commit('getDataForKTSpecificFailure', error);
                    throw error.data;
                }
            );
    },
}

export const bestandItem = {
    namespaced: true,
    state,
    actions,
    mutations
};