<template>

    <div id="app">

        <b-navbar toggleable="lg" type="dark" variant="info">
            <b-navbar-brand :to="{name: 'home'}">Bestandsbericht</b-navbar-brand>
        </b-navbar>
        <router-view class="m-lg-2 mr-2"></router-view>
    </div>

</template>

<script>

    export default {
        name: 'App',
        computed: {
            loggedUser() {
                return this.$store.state.identity.user;
            },
        },
        components: {
        },
        methods: {
            logOut() {
                let thisHelper = this;
                this.$store.dispatch("identity/logout", this.input).then(function () {
                    thisHelper.$router.push({ name: "login" })
                }).catch(error => {
                    console.log(error);
                });

            }
        }
    }
</script>

<style>
</style>
