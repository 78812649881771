<template>
    <div class="post">

        <b-row>
            <b-col>
                <h4>Beschaffungsarts: {{beschaffungsarts.toString()}}</h4>
                <b-table striped
                         bordered
                         hover
                         responsive
                         small
                         head-variant="dark"
                         :busy="tableDataLoading"
                         :items.sync="tableData[ktTableNumber]"
                         :fields="table.fields"
                         tbody-class="align-middle"
                         :sort-by.sync="table.sortBy"
                         :sort-desc.sync="table.sortDesc"
                         class="mt-2"
                         v-if="tableData[ktTableNumber]">

                    <template #cell(actions)="data">
                        <b-button size="sm" v-if="data.item.disponent != 'Celkový součet'" @click="getKTRowDetail(data.item.disponent)" class="mr-1">Detail</b-button>
                        <b-button size="sm" v-if="data.item.disponent != 'Celkový součet'" @click="getExcelForKTRowDetail(data.item.disponent)" variant="success">Excel</b-button>
                    </template>

                    <template #head(actions)="data">
                        <b-button size="sm" variant="success" @click="getKTExcel">Excel</b-button>
                    </template>

                    <template #cell(disponent)="data">
                        <b v-if="data.item.disponent === 'Celkový součet'" class="font-weight-bold">
                            {{ data.item.disponent }}
                        </b>
                        <span v-else>
                            {{ data.item.disponent }}
                        </span>
                    </template>

                    <template #cell(sumSPxBestand)="data">
                        <b v-if="data.item.disponent === 'Celkový součet'" class="font-weight-bold">
                            {{ data.item.sumSPxBestand }}
                        </b>
                        <span v-else>
                            {{ data.item.sumSPxBestand }}
                        </span>
                    </template>

                    <template #cell(sumSPxVerbrauch12Monate)="data">
                        <b v-if="data.item.disponent === 'Celkový součet'" class="font-weight-bold">
                            {{ data.item.sumSPxVerbrauch12Monate }}
                        </b>
                        <span v-else>
                            {{ data.item.sumSPxVerbrauch12Monate }}
                        </span>
                    </template>

                    <template #cell(sumSPxBestelmenge)="data">
                        <b v-if="data.item.disponent === 'Celkový součet'" class="font-weight-bold">
                            {{ data.item.sumSPxBestelmenge }}
                        </b>
                        <span v-else>
                            {{ data.item.sumSPxBestelmenge }}
                        </span>
                    </template>

                    <template #cell(uh)="data">
                        <b v-if="data.item.disponent === 'Celkový součet'" class="font-weight-bold">
                            {{ data.item.uh }}
                        </b>
                        <span v-else>
                            {{ data.item.uh }}
                        </span>
                    </template>

                </b-table>

            </b-col>
        </b-row>

    </div>
</template>

<script lang="js">

    import { defineComponent } from 'vue';

    export default defineComponent({
        name: "ktTable",
        components: {
        },
        props: {
            beschaffungsarts: [],
            parentFunc: null
        },
        data() {
            return {
                ktTableNumber: null,
                lastImportId: -1,
                table: {
                    sortBy: 'age',
                    sortDesc: false,
                    fields: [
                        { key: 'disponent', label: "Disponent", sortable: false, allowFilterCheckbox: false, class: "text-left" },
                        { key: 'sumSPxBestand', label: "Součet z SP x Bestand", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sumSPxBestelmenge', label: "Součet z SP x Bestelmenge", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'sumSPxVerbrauch12Monate', label: "Součet z SP x Verbrauch 12. Monate", sortable: false, allowFilterCheckbox: false, class: "text-right" },
                        { key: 'uh', label: "UH", sortable: false, allowFilterCheckbox: false, class: "text-right" },     
                        { key: 'actions', label: "Akce", sortable: false, allowFilterCheckbox: false },
                    ],
                    staticTexts: {
                        loading: "Nahrávám...",
                        emptyTable: "Zatím zde nejsou žádná data"
                    }
                },
            };
        },
        created() {        
        },
        computed: {
            tableData() {
                return this.$store.state.bestandItem.bestandKTTables.data;
            },
            tableDataLoading() {
                return this.$store.state.bestandItem.bestandKTTables.status.loading;
            },           
        },
        methods: {
            checkIfImportIdChange(selectedImportId) {
                if (selectedImportId > 0) {
                    this.lastImportId = selectedImportId;
                }
            },
            reloadData(selectedImportId, ktTableNumber) {
                this.ktTableNumber = ktTableNumber


                this.checkIfImportIdChange(selectedImportId);

                return this.$store.dispatch("bestandItem/getDataForKT", {
                    beschaffungsarts: this.beschaffungsarts,
                    importId: this.lastImportId,
                    ktTableId: this.ktTableNumber
                });
            },
            getKTRowDetail(selectedDisponent) {
                this.parentFunc(selectedDisponent, this.beschaffungsarts, "getDataForKTRowDetail");
            },
            getKTExcel() {
                return this.$store.dispatch("excelReport/getKTTableExcel", {
                    beschaffungsarts: this.beschaffungsarts,
                    importId: this.lastImportId
                });
            },
            getExcelForKTRowDetail(selectedDisponent) {
                return this.$store.dispatch("excelReport/getExcelForKTRowDetail", {
                    beschaffungsarts: this.beschaffungsarts,
                    importId: this.lastImportId,
                    disponent: selectedDisponent,
                    isSpecific: false
                });
            }
        },
    });
</script>

<style>
</style>