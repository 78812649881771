import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

/* add font awesome icon component */
Vue.component('fa', FontAwesomeIcon)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(require('vue-moment'));

import router from './router'
import store from './store'

import { authHeader } from './helpers';

axios.interceptors.request.use(config => {
    //NProgress.start();
    config.headers = authHeader();
    return config;
}, (error) => {
    //NProgress.done();
    console.log(error.data);
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');