
//import { getCurrentInstance } from 'vue'
import { identityService } from '@/services'
import router from '@/router'

const user = JSON.parse(localStorage.getItem('user'));
const state = user ? { status: { loggedIn: true }, user } : { status: {}, user: null };

const mutations = {

    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    changePasswordRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    changePasswordSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    changePasswordFailure(state) {
        state.status = {};
        state.user = null;
    },

}

const actions = {

    login: async function ({ commit }, { email, password }) {

        commit('loginRequest', { email });
        await identityService.login({ email, password })
            .then(
                user => {
                    commit('loginSuccess', user);
                    //const internalInstance = getCurrentInstance()
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                    console.error(error)
                    throw error.data;
                }
            );
    },

    logout({ commit }) {
        identityService.logout();
        commit('logout');
        router.push('/');
    },

    changePassword: async function ({ commit }, data) {
        commit('changePasswordRequest');
        await identityService.changePassword(data)
            .then(
                data => {
                    console.log("data:", data);
                    commit('changePasswordSuccess', data);
                },
                error => {
                    commit('changePasswordFailure', error);
                    throw error.data;
                }
            );
    }

}

export const identity = {
    namespaced: true,
    state,
    actions,
    mutations
};