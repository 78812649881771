
import axios from 'axios'

const baseUrl = '/api/identity/';

export const identityService = {
    login,
    logout,
    changePassword
};


async function login(payload) {
    try {
        var response = await axios.post(`${baseUrl}Login`, payload)

        if (response.data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes        
            localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
    }
    catch (ex) {
        console.err(ex);
        throw ex;
    }
}

async function changePassword(payload) {
    var response = await axios.put(`${baseUrl}ChangePassword`, payload)
    return response.data;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}