
import axios from 'axios'

const baseUrl = '/api/importData/';

export const importDataService = {
    startImport,
};


async function startImport(payload) {

    var formData = new FormData();
    formData.append("Excel", payload.excel)

    try {
        var response = await axios.post(`${baseUrl}StartImport`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    }
    catch (ex) {
        console.err(ex);
        throw ex;
    }
}